import _unistUtilVisit from "unist-util-visit";
var exports = {};
var visit = _unistUtilVisit;
exports = removePosition;

function removePosition(node, force) {
  visit(node, force ? hard : soft);
  return node;
}

function hard(node) {
  delete node.position;
}

function soft(node) {
  node.position = undefined;
}

export default exports;